
import Footer from "@/components/layout/Footer.vue";
import { Component, Inject, Prop, Vue } from "vue-property-decorator";
import { Action } from "vuex-class";

@Component({
  name: "LoggedOut",
  components: { Footer },
})
export default class LoggedOut extends Vue {
  @Inject() readonly globalBus!: Vue;
  @Action("clearMenuConfiguration", { namespace: "application" })
  clearMenuConfiguration!: () => void;
  @Action("clearViewContext", { namespace: "application" })
  clearViewContext!: () => void;
  @Prop({ default: false })
  private organization!: string;
  bgImagePath = require("@/assets/images/banner_effect.jpg");
  grayLogoSrc = require("@/assets/images/jfrog_gray_logo.png");
  backgroundStyles = {
    backgroundImage: `linear-gradient(to right, rgba(114, 114, 114,0.2), rgba(37, 37, 37,0.2)), url("${this.bgImagePath}")`,
    backgroundPosition: "top left",
    backgroundRepeat: "repeat",
    backgroundSize: "cover",
  };

  get transitionName() {
    switch (this.$route.name) {
      case "login":
        return "page-to-right";
      case "forgot-password":
      case "register-now":
      case "google-authenticator":
        return "page-to-left";
      default:
        return "fade";
    }
  }
  mounted() {
    this.globalBus.$emit("shouldReloadMainTheme");
    this.clearMenuConfiguration();
    this.clearViewContext();
    this.$jfModal.closeAll();
  }
}
