
import RegistrationHeaderFromStep1 from "@/components/views/loggedOut/headerComponents/registration/RegistrationHeaderFromStep1.vue";
import RegistrationHeaderRegular from "@/components/views/loggedOut/headerComponents/registration/RegistrationHeaderRegular.vue";
import LoggedOut from "@/components/views/loggedOut/LoggedOut.vue";
import { GoogleUser, LoginHeaderType, RegisterNowHeaderType, RegistrationHeaderType } from "@/types/localtypes";
import { JFFormControl, JFTextField } from "jfrog-ui-vue-essentials";
import { isRestClientError, SsoRegistrationRequest, storeErrorsMapping } from "@jfrog-ba/myjfrog-common";
import { Component, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import logger from "@/util/logger";
import GoogleLogin from "@/components/common/GoogleLogin.vue";
declare var window: any;

@Component({
  name: "Registration",
  $_veeValidate: { validator: "new" },
  components: {
    JFTextField,
    JFFormControl,
    LoggedOut,
    GoogleLogin,
  },
})
export default class Registration extends Vue {
  @Action("showAppLoadingMask", { namespace: "application" })
  showAppLoadingMask!: () => void;
  @Action("hideAppLoadingMask", { namespace: "application" })
  hideAppLoadingMask!: () => void;
  @Getter("registrationHeaderType", { namespace: "dynamicHeader" })
  registrationHeaderType!: RegistrationHeaderType;
  @Action("setLoginHeaderType", { namespace: "dynamicHeader" })
  setLoginHeaderType!: (headerType: LoginHeaderType) => void;
  @Action("setRegisterNowHeaderType", { namespace: "dynamicHeader" })
  setRegisterNowHeaderType!: (headerType: RegisterNowHeaderType) => void;
  viewLoaded = false;
  token = "";
  password = "";
  ssoRegistrationRequest: SsoRegistrationRequest = { registrationToken: "", ssoToken: "", ssoOrigin: "" };
  validatePassword = "";
  tooltip =
    "&#x2022; Password should be 8 - 32 characters long<br>" +
    "&#x2022; Password should contain at least one uppercase and one lowercase character<br>" +
    "&#x2022; Password should contain at least one number<br>" +
    "&#x2022; Password should contain at least one special character";

  newPasswordLabelIcon = {
    iconClass: "icon-info",
    tooltipText: this.tooltip,
  };

  get newPasswordFieldName() {
    return this.$utils.generateUniqInputName("newPassword");
  }
  get newPasswordConfirmationFieldName() {
    return this.$utils.generateUniqInputName("confirmPassword");
  }

  onKeyDown(e: any) {
    if (["shift", "capslock"].includes(e.key.toLowerCase())) {
      return;
    }
    const elementName = e.target.name;
    if (this.errors.items.find(item => item.field === elementName)) {
      this.errors.remove(elementName);
    }
  }

  onBlur(e: any) {
    if (this.viewLoaded) {
      this.$validator.validate(e.target.name);
    }
  }

  get registrationHeaderComponent() {
    switch (this.registrationHeaderType) {
      case "fromStep1":
        return RegistrationHeaderFromStep1;
      case "regular":
      default:
        return RegistrationHeaderRegular;
    }
  }
  get shouldDisableSubmitBtn() {
    return this.errors.any() || !this.password || !this.validatePassword;
  }

  async register() {
    if ((!window.gapi || !window.gapi.auth2) && !(await this.$validator.validateAll())) {
      return;
    }
    this.showAppLoadingMask();
    try {
      this.ssoRegistrationRequest.ssoToken
        ? await this.$jfUsers.registerAndLoginSsoUser(this.ssoRegistrationRequest)
        : await this.$jfUsers.register({
            password: this.password,
            token: this.token,
          });
      await this.setLoginHeaderType("registrationComplete");
      await this.hideAppLoadingMask();
      this.$router.push({ path: "/login" });
    } catch (e) {
      if (window.gapi && window.gapi.auth2) {
        window.gapi.auth2.getAuthInstance().disconnect();
      }
      this.$log.error(e);
      this.hideAppLoadingMask();
      let errorMessage = this.$jfMessages.registration_error;
      let notificationClasses = [];
      let shouldRedirectToRegisterNow = false;
      let shouldRedirectToLogin = false;

      if (isRestClientError(e) && e.httpBody) {
        if (e.httpBody.result === storeErrorsMapping.registration.badPolicy) {
          // when receiving bad policy errors the RestClientError's httpBody Object will contain a errors array that we need to display in list
          errorMessage = this.errorsToHTMLList(e.httpBody.errors) || e.httpBody.message;
          notificationClasses.push("text-left");
        } else if (e.httpBody.result === storeErrorsMapping.registration.tokenExpired) {
          await this.setRegisterNowHeaderType("linkNotActive");
          shouldRedirectToRegisterNow = true;
        } else if (e.httpBody.result === storeErrorsMapping.registration.userAlreadyRegistered) {
          shouldRedirectToLogin = true;
        }
      }

      if (!shouldRedirectToRegisterNow && !shouldRedirectToLogin) {
        this.$jfNotification.error({
          text: errorMessage,
          data: { textWrapperClasses: notificationClasses },
        });
      }

      if (shouldRedirectToRegisterNow) {
        this.$router.push({ path: "/register-now" });
      }
      if (shouldRedirectToLogin) {
        this.$router.push({ path: "/login" });
      }
    }
  }

  errorsToHTMLList(errors?: string[]): string | null {
    return errors ? errors.map(error => `<div mb-2>&#x2022; ${error}.</div>`).join("") : null;
  }

  created() {
    this.token = this.$route.query.token as string;
  }
  mounted() {
    ((this.$refs["register-pwd1"] as Vue).$el.querySelector("input") as HTMLInputElement).focus();
    setTimeout(() => {
      this.viewLoaded = true;
    }, 150);
  }

  onSignIn(googleUser: GoogleUser) {
    this.errors.clear();
    this.ssoRegistrationRequest.ssoToken = googleUser.getAuthResponse().id_token;
    this.ssoRegistrationRequest.ssoOrigin = "google";
    this.ssoRegistrationRequest.registrationToken = this.token;
    this.register();
  }

  onFailure(error: string) {
    this.$log.error(error);
  }
}
